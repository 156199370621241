import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus';
// import locale from '../node_modules/element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import locale from 'element-plus/lib/locale/lang/zh-cn'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'
import axios from 'axios';
import {baseUrl} from '@/js/util.js'
// var AES = require("crypto-js/aes");
// var Base64 = require("js-base64").Base64;
import { getStorage } from '@/js/common'
//验证是否登录，路由拦截
router.beforeEach(async (to, from, next) => {

  // setStorage("routeData",to)
  // store.commit('SetrouterTitle',{
  //   title: to.meta.title,
  // })
  let token = getStorage('ftoken')?true:false;
  let toPath = to.path;
  if (window.__axiosCancelArr && window.__axiosCancelArr.length) {
    window.__axiosCancelArr.forEach((ele, index) => {
      ele.cancel();
      delete window.__axiosCancelArr[index];
    })
  }
  console.log(toPath,token);
  if (toPath == '/loginPage' ) {
    next();
  }else if(toPath == '/forgotPassword'){
    next();
  }else if(toPath == '/registration'){
    next();
  }else{
    token?next():next("/loginPage")
  }
  
  
})
let isToken=false
axios.interceptors.response.use(res =>{
  if(!res){
    res = {data:null}
  } 
  console.log(res);
  if(res.data.code == 1003){ 
    if(!isToken){
      isToken = true;
       ElMessage({
      message: res.data.message,
      type: "error",
      showClose: true,
    });
    sessionStorage.clear();
    vm.$router.push("/loginPage"); 
    console.log(res,'登录失效')
    }
   
  }else if(res.data.code == 1002){
    
    if(!isToken){
      isToken = true;
       ElMessage({
      message: res.data.message,
      type: "error",
      showClose: true,
    });
    sessionStorage.clear();
    vm.$router.push("/loginPage"); 
    }
   
  }

   return res 
}, error => {
    console.log(error) 
    return{data:null}
})
window.addEventListener('storage', function() {
  sessionStorage.clear()
  vm.$router.push("/loginPage"); 
})
axios.interceptors.request.use(function (config) {
    if(config.url.indexOf(baseUrl) != -1){
      let token = getStorage('ftoken');
      if (token) {  
          config.headers.token = token
          config.headers.operate = true      
      }else{
        config.headers.operate = true
      }
      
    } 
   
    return config;
  }, function (error) { 
    return Promise.reject(error);
  });
const vm = createApp(App).use(store).use(router).use(vue3videoPlay).use(ElementPlus, { locale, }).mount('#app')
export default vm