

// 存储localstorage数据
export const setStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
}
// 取出localstorage数据
export const getStorage = (key) => { 
  if(!sessionStorage.getItem(key) ||sessionStorage.getItem(key) == 'undefined'){
    return ""
  }else{
    return JSON.parse(sessionStorage.getItem(key));
  }
}
// 删除localstorage数据
export const removeStorage = (key) => {
  sessionStorage.removeItem(key);
}


export const Newdata=(datas)=> {
  return datas.substr(0,10)
  
}