import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/pages/index/index.vue'),
  },
  {
    path: '/loginPage',
    name: 'loginPage',
    component: () => import('@/views/pages/login/loginPage'),
  },{
    path: '/studentRegistration',
    name: 'studentRegistration',
    component: () => import('@/views/pages/register/studentRegistration'),
  },{
    path: '/techarRegister',
    name: 'techarRegister',
    component: () => import('@/views/pages/register/techarRegister'),
  },{
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/pages/register/registration'),
  },{
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/pages/register/forgotPassword'),
  },{
    path: '/comprehensiveTraining',
    name: 'comprehensiveTraining',
    component: () => import('@/views/pages/intelligentConstruction/comprehensiveTraining.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    redirect: 'loginPage',
    children: [
      {
        path: '/home/index',
        name: 'index',
        component: () => import('@/views/pages/index/index.vue'),
      },
      {
        path: '/home/courseManagement',
        name: 'courseManagement',
        component: () => import('@/views/pages/courseManagement/courseManagement.vue'),
      },
      {
        path: '/home/courseManagementDetail',
        name: 'courseManagementDetail',
        component: () => import('@/views/pages/courseManagement/courseManagementDetail.vue'),
      },
      {
        path: '/home/digitalDesign',
        name: 'digitalDesign',
        component: () => import('@/views/pages/digitalDesign/digitalDesign.vue'),
      }, {
        path: '/home/dgintelzhgd',
        name: 'dgintelzhgd',
        component: () => import('@/views/pages/digitalDesign/dgintelzhgd.vue'),
      },
      {
        path: '/home/digitalDesignDetail',
        name: 'digitalDesignDetail',
        component: () => import('@/views/pages/digitalDesign/digitalDesignDetail.vue'),
      },
      {
        path: '/home/courseDetails',
        name: 'courseDetails',
        component: () => import('@/views/pages/tasks/courseDetails.vue'),
      },
      {
        path: '/home/practicalTraining',
        name: 'practicalTraining',
        component: () => import('@/views/pages/tasks/practicalTraining.vue'),
      },
      {
        path: '/home/intelligentConstruction',
        name: 'intelligentConstruction',
        component: () => import('@/views/pages/intelligentConstruction/intelligentConstruction.vue'),
      },
      {
        path: '/home/intelligentConstructionDetail',
        name: 'intelligentConstructionDetail',
        component: () => import('@/views/pages/intelligentConstruction/intelligentConstructionDetail.vue'),
      },
      {
        path: '/home/resourcePool',
        name: 'resourcePool',
        component: () => import('@/views/pages/resourcePool/resourcePool.vue'),
      },
      {
        path: '/home/courseList',
        name: 'courseList',
        component: () => import('@/views/pages/courseList/courseList.vue'),
      },
      {
        path: '/home/studentCenter',
        name: 'studentCenter',
        component: () => import('@/views/pages/studentCenter/studentCenter.vue'),
      }
      , {
        path: '/home/task01',
        name: 'task01',
        component: () => import('@/views/pages/tasks/task01.vue'),
      }, {
        path: '/home/task02',
        name: 'task02',
        component: () => import('@/views/pages/tasks/task02.vue'),
      }, {
        path: '/home/task03',
        name: 'task03',
        component: () => import('@/views/pages/tasks/task03.vue'),
      }, {
        path: '/home/task04',
        name: 'task04',
        component: () => import('@/views/pages/tasks/task04.vue'),
      }, {
        path: '/home/task05',
        name: 'task05',
        component: () => import('@/views/pages/tasks/task05.vue'),
      },
      {
        path: '/home/imintelzhgd',
        name: 'imintelzhgd',
        component: () => import('@/views/pages/intelligentOAM/imintelzhgd.vue'),
      },
      {
        path: '/home/intelligentOAM',
        name: 'intelligentOAM',
        component: () => import('@/views/pages/intelligentOAM/intelligentOAM.vue'),
      },
      {
        path: '/home/intelligentOAMDetail',
        name: 'intelligentOAMDetail',
        component: () => import('@/views/pages/intelligentOAM/intelligentOAMDetail.vue'),
      },
      {
        path: '/home/intelligentProduction',
        name: 'intelligentProduction',
        component: () => import('@/views/pages/intelligentProduction/intelligentProduction.vue'),
      },
      {
        path: '/home/igintelzhgd',
        name: 'igintelzhgd',
        component: () => import('@/views/pages/intelligentProduction/igintelzhgd.vue'),
      },
      {
        path: '/home/intelligentProductionDetail',
        name: 'intelligentProductionDetail',
        component: () => import('@/views/pages/intelligentProduction/intelligentProductionDetail.vue'),
      },

      {
        path: '/home/caseShare',
        name: 'caseShare',
        component: () => import('@/views/pages/caseShare/caseShare.vue'),
      },
      {
        path: '/home/caseShareDetail',
        name: 'caseShareDetail',
        component: () => import('@/views/pages/caseShare/caseShareDetail.vue'),
      },


      {
        path: '/home/educational',
        name: 'educational',
        component: () => import('@/views/pages/educational/educational.vue'),
      },
      {
        path: '/home/intelzhgd',
        name: 'intelzhgd',
        component: () => import('@/views/pages/intelligentConstruction/intelzhgd.vue'),
      },{
        path: '/home/industryDynamicsDetails',
        name: 'industryDynamicsDetails',
        component: () => import('@/views/pages/industryDynamicsDetails/index.vue'),
      },{
        path: '/home/courseTeaching',
        name: 'courseTeaching',
        component: () => import('@/views/pages/courseTeaching/index.vue'),
      },{
        path: '/home/courseDetails',
        name: 'courseDetails',
        component: () => import('@/views/pages/courseTeaching/courseDetails.vue'),
      },
      {
        path: '/home/courseDetailsCopy',
        name: 'courseDetailsCopy',
        component: () => import('@/views/pages/courseTeaching/courseDetailsCopy.vue'),
      },
      {
        path: '/home/courseIntroductionDetails',
        name: 'courseIntroductionDetails',
        component: () => import('@/views/pages/courseTeaching/courseIntroductionDetails.vue'),
      },{
        path: '/home/digitalTraining',
        name: 'digitalTraining',
        component: () => import('@/views/pages/digitalTraining/index.vue'),
      },{
        path: '/home/systemDetails',
        name: 'systemDetails',
        component: () => import('@/views/pages/digitalTraining/systemDetails.vue'),
      },{
        path: '/home/examine',
        name: 'examine',
        component: () => import('@/views/pages/digitalTraining/examine.vue'),
      },{
        path: '/home/intelexamine',
        name: 'intelexamine',
        component: () => import('@/views/pages/intelligentConstruction/intelexamine.vue'),
      },{
        path: '/home/evaluationCenter',
        name: 'evaluationCenter',
        component: () => import('@/views/pages/evaluationCenter/index.vue'),
      },{
        path: '/home/boutiqueCases',
        name: 'boutiqueCases',
        component: () => import('@/views/pages/boutiqueCases/index.vue'),
      },{
        path: '/home/casesDetail',
        name: 'casesDetail',
        component: () => import('@/views/pages/boutiqueCases/casesDetail.vue'),
      },{
        path: '/home/processDocuments',
        name: 'processDocuments',
        component: () => import('@/views/pages/boutiqueCases/processDocuments.vue'),
      },{
        path: '/home/drawings',
        name: 'drawings',
        component: () => import('@/views/pages/boutiqueCases/drawings.vue'),
      },{
        path: '/home/bimModel',
        name: 'bimModel',
        component: () => import('@/views/pages/boutiqueCases/bimModel.vue'),
      },{
        path: '/home/professionalLecture',
        name: 'professionalLecture',
        component: () => import('@/views/pages/boutiqueCases/professionalLecture.vue'),
      },{
        path: '/home/artisan',
        name: 'artisan',
        component: () => import('@/views/pages/boutiqueCases/artisan.vue'),
      },{
        path: '/home/artisanDetail',
        name: 'artisanDetail',
        component: () => import('@/views/pages/boutiqueCases/artisanDetail.vue'),
      },{
        path: '/home/helpCenter',
        name: 'helpCenter',
        component: () => import('@/views/pages/courseList/helpCenter.vue'),
      },
      {
        path: '/home/studenthelpCenter',
        name: 'studenthelpCenter',
        component: () => import('@/views/pages/studentCenter/helpCenter.vue'),
      },{
        path: '/home/addSystemExperience',
        name: 'addSystemExperience',
        component: () => import('@/views/pages/courseList/addSystemExperience.vue'),
      },{
        path: '/home/classDetails',
        name: 'classDetails',
        component: () => import('@/views/pages/courseList/classDetails.vue'),
      },{
        path: '/home/exerciseDistribution',
        name: 'exerciseDistribution',
        component: () => import('@/views/pages/courseList/exerciseDistribution.vue'),
      },{
        path: '/home/afterClassExercises',
        name: 'afterClassExercises',
        component: () => import('@/views/pages/courseList/afterClassExercises.vue'),
      },{
        path: '/home/createExercises',
        name: 'createExercises',
        component: () => import('@/views/pages/courseList/createExercises.vue'),
        meta:{
          keepAlive: true,
          isBack:false,
        }
      },{
        path: '/home/exerciseScore',
        name: 'exerciseScore',
        component: () => import('@/views/pages/courseList/exerciseScore.vue'),
      },{
        path: '/home/examination',
        name: 'examination',
        component: () => import('@/views/pages/courseList/examination.vue'),
      },{
        path: '/home/classTime',
        name: 'classTime',
        component: () => import('@/views/pages/courseList/classTime.vue'),
      },{
        path: '/home/examinationIndex',
        name: 'examinationIndex',
        component: () => import('@/views/pages/courseList/examinationIndex.vue'),
      },{
        path: '/home/addDigitalTraining',
        name: 'addDigitalTraining',
        component: () => import('@/views/pages/courseList/addDigitalTraining.vue'),
      },
      {
        path:'/home/Centerintelexamine',
        name: 'Centerintelexamine',
        component: () => import('@/views/pages/studentCenter/Centerintelexamine.vue'),
      },
      {
        path:'/home/testPaperPreview',
        name: 'testPaperPreview',
        component: () => import('@/views/pages/courseList/testPaperPreview.vue'),
      },
      {
        path:'/home/UploadCourse',
        name: 'UploadCourse',
        component: () => import('@/views/pages/courseList/UploadCourse.vue'),
      },
      {
        path:'/home/Dohomework',
        name: 'Dohomework',
        component: () => import('@/views/pages/studentCenter/Push/Dohomework.vue'),
      },
      {
        path:'/home/studentExamination',
        name: 'studentExamination',
        component: () => import('@/views/pages/studentCenter/Push/studentExamination.vue'),
      },
      {
        path:'/home/HomeworkAfterClass',
        name: 'HomeworkAfterClass',
        component: () => import('@/views/pages/courseTeaching/HomeworkAfterClass.vue'),
      },


    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
