<template>
  <div id="app-wrap">
    <el-config-provider :locale="locale">
    <slot name="app"></slot>
  </el-config-provider>
   <router-view/>
  </div>
</template>

<script> 
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: 'app',
  setup() {
    let locale = zhCn
    return {
      locale
    }
  }
}
</script>

<style lang="scss" scoped>
#app-wrap {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
</style>
