import { createStore } from 'vuex'
import {getStorage} from '@/js/common'
export default createStore({
  state: {
    narbarMenuActive: 1,
    bclist:[],
    detailData:getStorage('detailData')?getStorage('detailData'):'',
    intellData:getStorage('intellData')?getStorage('intellData'):'',
    videoData:getStorage('videoData')?getStorage('videoData'):'',
  },
  mutations: {
    SetnarbarMenuActive(state,narbarMenuActive){
      state.narbarMenuActive = narbarMenuActive;
    },
    SetdetailData(state,ndata){
      state.detailData=ndata;
    },
    SetintellData(state,ndata){
      state.intellData=ndata;
    },
    Setbclist(state,bclist){
      state.bclist = bclist;
    },
    SetbvideoData(state,videoData){
      state.videoData = videoData;
    },
  },
  actions: {
  },
  modules: {
  }
})
